import React from "react"
import styled from "styled-components"
import Grid from "../zzz/layout/grid"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../zzz/layout"
import SEO from "../components/SEO/seo"
import Heading from "../zzz/organisms/heading"

const TnCWrapper = styled.div`
  margin: 40px 0;
  strong {
    font-weight: 500;
  }

  h1 {
    margin-bottom: 20px;
  }

  h3,
  h2 {
    margin: 15px 0;

    strong {
      font-weight: 300;
    }
  }
`
const PageContent = styled.div`
  ol {
    list-style: auto;
    padding-left: 17px;
    li {
      ol {
        list-style: lower-alpha;
      }
    }
  }
`

const TnCPage = ({ location }) => {
  const page = useStaticQuery(graphql`
    {
      page: localWpGraphQlPages(slug: { eq: "terms-and-conditions" }) {
        seo {
          yoastTitle
          yoastDescription
          yoastMeta
          yoastSchema
          woocommerceSchema
        }
        title
        content
        pageId
      }
    }
  `)

  const seo = page.page.seo

  return (
    <Layout location={location}>
      <SEO
        title={page.page.title}
        location={location}
        post_id={page.page.pageId}
        seo={seo}
      />
      <TnCWrapper>
        <Grid>
          <h1 dangerouslySetInnerHTML={{ __html: page.page.title }} />
          <PageContent
            dangerouslySetInnerHTML={{ __html: page.page.content }}
          />
        </Grid>
      </TnCWrapper>
    </Layout>
  )
}

export default TnCPage
